/**
 * @name: 推荐商品接口
 * @author: itmobai
 * @date: 2023-06-02 13:40
 * @description：推荐商品接口
 * @update: 2023-06-02 13:40
 */
import {get, postJ} from "@/request";
import {IMarketShop} from "@/apis/marketingManage/shop/types";

/**
 * 推荐商品列表
 */
export const recommendProductQueryRecommendProductListApi = () => get<IMarketShop[]>("/golf/recommendProduct/queryRecommendProductList")
/**
 * 推荐商品修改
 * @param data
 */
export const recommendProductModifyApi = (data: Partial<IMarketShop>) => postJ("/golf/recommendProduct/modify", data)
/**
 * 批量删除
 * @param ids
 */
export const recommendProductBatchDeleteApi = (ids: string) => get("/golf/recommendProduct/batchDelete", {ids})
/**
 * 上下架
 * @param id
 * @param status
 */
export const recommendProductUpAndDownShelfApi = (id: string, status: number)  => postJ("/golf/recommendProduct/upAndDownShelf", {id, status})
/**
 * 创建
 * @param data
 */
export const recommendProductCreateApi = (data: IMarketShop) => postJ("/golf/recommendProduct/create", data)
